import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageWrap from '../components/page-wrap'

const Page = props => {
  return (
    <Layout>
      <SEO title="Projects" />
      <PageWrap>
        <h1>Projects</h1>
        <ul className='no-style sub-tag'>
          <li><a href="https://www.yourpremierbank.com" target="_blank">Your Premier Bank</a>
            <ul>
              <li>Kentico</li>
              <li>Tailwind CSS</li>
              <li>Alpine JS</li>
            </ul>
          </li>
          <li><a href="https://www.mactrailer.com" target="_blank">MAC Trailer</a>
            <ul>
              <li>HubSpot CMS</li>
              <li>Tailwind CSS</li>
            </ul>
          </li>
          <li><a href="https://www.fallprotect.com" target="_blank">Diversified Fall Protection</a>
            <ul>
              <li>HubSpot CMS</li>
            </ul>
          </li>
          <li><a href="https://www.unitedtitanium.com" target="_blank">United Titanium</a>
            <ul>
              <li>Apoxe .NET CMS</li>
              <li>Angular v1 part builder app</li>
            </ul>
          </li>
          <li><a href="https://tsnapp.azurewebsites.net" target="_blank">Goodyear TSN Pricing Tool</a>
            <ul>
              <li>React JS</li>
              <li>Tailwind CSS</li>
            </ul>
          </li>
          <li><a href="https://github.com/brockduncan/usacs-app" target="_blank">USACS Recruiting iOS App</a>
            <ul>
              <li>React Native</li>
              <li>Ask for collaborator access</li>
            </ul>
          </li>
          <li><a href="https://airtank-nps-g7jhb8ha6-brockduncan.vercel.app" target="_blank">NPS Parks</a>
            <ul>
              <li>Next JS</li>
              <li>NPS API</li>
            </ul>
          </li>
        </ul>
        <h2>Provided features and support for these projects</h2>
        <ul className='no-style sub-tag'>
          <li><a href="https://usacs.com" target="_blank">United Acute Care Solutions</a>
            <ul>
              <li>Drupal</li>
              <li>Tailwind CSS</li>
              <li>Alpine JS</li>
              <li>Custom module to add cron-driven URL alias rewrites</li>
              <li>Custom module to add automated email messages</li>
            </ul>
          </li>
          <li><a href="https://acelacbd.com" target="_blank">Acela CBD</a>
            <ul>
              <li>Wordpress + WooCommerce</li>
              <li>Plugin to extend products and checkout</li>
            </ul>
          </li>
          <li><a href="https://usacs.com" target="_blank">Stark Help Central</a>
            <ul>
              <li>Wordpress</li>
              <li>Custom Post Types</li>
              <li>New ACF fields</li>
            </ul>
          </li>
        </ul>
      </PageWrap>
    </Layout>
  )
}

export default Page
