import React from 'react'
import { css } from '@emotion/core'

// using this on pages that don't use Layout

export default class PageWrap extends React.Component {
  render() {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          flex-flow: column nowrap;
          padding: 8rem 0;
        `}
      >
        {this.props.children}
      </div>
    )
  }
}
